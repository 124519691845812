















import { defineComponent } from '@vue/composition-api'
import { swiper, swiperSlide } from 'vue-awesome-swiper'
import { techList } from '../constants/index'

export default defineComponent({
  name: 'AsyncMobileTechList',
  components: {
    swiper,
    swiperSlide
  },
  setup() {
    return {
      techList,
      swiperOption: {
        pagination: { el: '.swiper-pagination' },
        autoplay: false
      }
    }
  }
})
